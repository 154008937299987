import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const ContactPage = () => {
    return(
         <Layout>
             <Head title="Contact" />
            <h1>Contact</h1>
            <p>Contact me here.</p>
        </Layout>
    )
}

export default ContactPage